import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/respornsive.css';


import Home from './pages/home';
import Freightforwarder from './pages/freightforwarder';
import Default from './pages/default';
import Containerdeposit from './pages/containerdeposit';

import Marine from './pages/marine';
import Carrireliability from './pages/carrireliability';
import Publicliability from './pages/marine';
import Allrisksinsurance from './pages/allrisksinsurance';
import Fireinsurance from './pages/fireinsurance';
import Motorinsurance from './pages/motorinsurance';
import Politycalinsurance from './pages/politycalinsurance';
import Erectioninsurance from './pages/marine';
import Professionalindemity from './pages/marine';
import Policy from './pages/policy';

import { Navigation, Route, Screen, glide, fade } from "react-tiger-transition";

import './assets/css/menu.css';

// import "react-tiger-transition/styles/main.min.css";

glide({
  name: 'glide-left'
});
glide({
  name: 'glide-right',
  direction: 'right'
});
fade({
  name: "default-fade"
});

function App() {

  return (
    <div className="App">
      <Router basename="">
      <Switch>
      {/* <Route exact path="/"><Home></Home></Route> */}
        <Navigation>
          <Route exact path="/"><Screen><Home></Home></Screen></Route>
          <Route path="/home/:name"><Screen><Home></Home></Screen></Route>
          <Route path="/freightforwarder"><Screen><Freightforwarder></Freightforwarder></Screen></Route>
          <Route path="/default"><Screen><Default></Default></Screen></Route>
          <Route path="/containerdeposit"><Screen><Containerdeposit></Containerdeposit></Screen></Route>

          <Route path="/marine"><Screen><Marine></Marine></Screen></Route>
          <Route path="/carrireliability"><Screen><Carrireliability></Carrireliability></Screen></Route>
          <Route path="/publicliability"><Screen><Publicliability></Publicliability></Screen></Route>
          <Route path="/allrisksinsurance"><Screen><Allrisksinsurance></Allrisksinsurance></Screen></Route>
          <Route path="/fireinsurance"><Screen><Fireinsurance></Fireinsurance></Screen></Route>
          <Route path="/motorinsurance"><Screen><Motorinsurance></Motorinsurance></Screen></Route>
          <Route path="/politycalinsurance"><Screen><Politycalinsurance></Politycalinsurance></Screen></Route>
          <Route path="/erectioninsurance"><Screen><Erectioninsurance></Erectioninsurance></Screen></Route>
          <Route path="/professionalindemity"><Screen><Professionalindemity></Professionalindemity></Screen></Route>
          <Route path="/policy"><Screen><Policy></Policy></Screen></Route>
        </Navigation>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
