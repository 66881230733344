import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
// interface IMenuProps {
//     isMenuOpen: boolean;
// }


const Menum = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen)  
      }

    const toggleMenu = () => {
        // setMenuOpen(true); 
        setMenuOpen(false)  
    }

    return (
        <>
{/* <div>555</div> */}
                <div className='right' >
                    
                    <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
                        <div onClick={() => toggleMenu()} className='my-3 text-end px-3 font-20 color-white'>
                            <i className="fa fa-times"></i>
                        </div>
                        <ul className='menu-m'>
                            <li>
                                <Link to="/home/myRef" onClick={() => toggleMenu()}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/home/aBout" onClick={() => toggleMenu()}>
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to="/home/ourProduct" onClick={() => toggleMenu()}>
                                   Our Product
                                </Link>
                            </li>
                            <li>
                                <Link to="/home/exPerience" onClick={() => toggleMenu()}>
                                    Experience
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/home/serVicesm" onClick={() => toggleMenu()}>
                                    Services
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/home/conTact" onClick={() => toggleMenu()}>
                                   Contact Us
                                </Link>
                            </li>
                        </ul>
                        
                    </Menu>
                    
                </div>
        </>
    )
};
export default Menum