import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import '../assets/css/layout/footer.css';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

// import homeImg from '../assets/img/home.svg';
// import margetImg from '../assets/img/marketplace.svg';
// import chatImg from '../assets/img/chat.svg';
// import menuImg from '../assets/img/menu.svg';
// import arrowright from '../assets/img/arrow-right.svg';

// import { Dropdown } from "react-bootstrap";
import iemail from '../assets/img/icon-email.png';
import itel from '../assets/img/icon-tel.png';
import copylink from '../assets/img/copylink.png';
import bgfooter from '../assets/img/bg-footer-2.png';

import { Link } from "react-tiger-transition";

const Footer = () => {

    let history = useHistory();

    // const { t } = useTranslation();
    const url = window.location.pathname;

    // const [menuactive, setMenuactive] = useState(false);

    const [memenu, setMemenu] = useState('0');

    const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //     if(url === '/marketplace' || url === '/marketplacedetail'){
    //         setMemenu('1');
    //     }else if(url === '/home'){
    //         setMemenu('0');
    //     }else if(url === '/chatlist'){
    //         setMemenu('2');
    //     }else if(url === '/wishlist' || url === '/mymarketplace' || url === '/history' || url === '/setting' || url === '/advertising' || url === '/myprofile'){
    //         setMemenu('3');
    //     }        

    // }, []);

    const changeMemenu = code => e => {

        if (code === '3') {
            setIsOpen(!isOpen);
        } else if (code === '0') {
            history.push("/home");
        } else if (code === '1') {
            history.push("/marketplace");
        } else if (code === '2') {
            history.push("/chatlist");
        }
        setMemenu(code);
    }
    return (
        <>

            <div className='box-footer' style={{ backgroundImage: `url(${bgfooter})`, overflow: "hidden" }}>
                <div className='row align-items-center'>
                    <div className="col-12 col-md-6 col-lg-6">
                        <h1 className='color-white text-start text-md-start'>CONTACT US</h1>
                        <div className='color-white text-start text-md-start'><h5 className=' weight-400'>ติดต่อเรา</h5></div>

                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className='text-start text-md-start color-white mt-1'>
                            บริษัท ไทยสตาร์ ชัวร์ตี้ จำกัด <br />
                            89/12 อาคารอมรพันธุ์ 205 ทาวเวอร์ 1 ชั้น 11
                            ซอยนาทอง ถนนรัชดาภิเษก แขวงดินแดง
                            เขตดินแดง กรุงเทพมหานคร 10400

                        </div>
                        <div className='textcenter text-md-start mt-3'>
                            <div className='d-inline-block text-start'>
                                <div className='d-inline-block'><img src={iemail} alt="" className="icon-email" /></div>
                                <div className='d-inline-block color-white'>thaistar@tssc.co.th</div>
                            </div>
                            <div className='d-inline-block text-center ms-5'>
                                <div className='d-inline-block'><img src={itel} alt="" className="icon-tel" /></div>
                                <div className='d-inline-block color-white'>0-2245-4082 ถึง 3</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-end bg-white box-copylink' >
                <span className='float-start text-start'>
                    <Link to="/policy" transition='default-fade' className='btn-link-default'>
                        <div className='policy-footer' style={{ lineHeight: "1" }}><b>PERSONAL DATA <br className='d-inblock d-md-none' />PROTEXTION POLICY</b></div>
                        <div className='policy-footer-thai'>
                            นโยบายคุ้มครองข้อมูลส่วนบุคคล
                        </div>
                    </Link>
                </span>
                <img src={copylink} alt="" className="policy-footer-img" />
            </div>
        </>
    );
};

export default Footer;