import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/header';
import Footer from '../layout/footer';


import bg from '../assets/img/bg-freight.png';
import bg2 from '../assets/img/bg-freight-2.png';
import bgm from '../assets/img/bg-freight-m.png';
// import bgm3 from '../assets/img/bg-freight-m-3.png';
import logo from '../assets/img/logotssc2.svg';
import logo1 from '../assets/img/logo-freight.svg';

import icon1 from '../assets/img/icon-f-1.svg';
import icon2 from '../assets/img/icon-f-2.svg';
import icon3 from '../assets/img/icon-f-3.svg';
import icon4 from '../assets/img/icon-f-4.png';

import img4 from '../assets/img/img-4.png';
import imgm4 from '../assets/img/img-m-4.png';
import img5 from '../assets/img/img-5.svg';

const Freightforwarder = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Header />

            <div className="wrapper bg-white ">
                <section className="box-index-res" >
                    <div className='logo-b-res  d-block d-md-none position-absolute' style={{ left: "20px", top: "20px",zIndex:"999999" }}>
                    <Link to="/home/myRef" transition='default-fade' className='btn-link-default border-0 pe-0'>
                        <img src={logo} alt="" className="" style={{ width: "100px" }} />
                    </Link>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 position-relative">
                                    <img src={bg} alt="" className="w-100 d-none d-md-block" />
                                    <img src={bgm} alt="" className="w-100 d-block d-md-none pt-5" />
                                    <div className='box-text-freight'>
                                        <h1 className='text-center text-md-start weight-700 mb-0'>OUR PRODUCTS</h1>
                                        <div className='color-green color-res-back text-center text-md-start'>ผลิตภัณฑ์</div>
                                    </div>
                                    <div className='box-text-freight-2'>
                                        <img src={logo1} alt="" className="w-75 px-4" />
                                        <div className='d-inline-block'>
                                            <h1 className='text-center text-md-center weight-700 mb-0'>FREIGHT FORWARDER</h1>
                                            <h1 className='text-center text-md-center weight-400 mb-0 mt-1'>LIABILITY</h1>
                                            <hr className='hr-green' />
                                            <div className='mt-0 text-center'>การชดเชยค่าเสียหายของผู้ส่งสินค้า</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='box-content box-bg-img' style={{ backgroundImage: `url(${bg2})` }}>
                                    <div className='row py-0 py-md-5 align-items-center'>
                                        <div className="col-12 col-md-5 col-lg-5 bgm4marine">
                                            <img src={img4} alt="" className="w-100 d-none d-md-block" />
                                            <img src={imgm4} alt="" className="w-100 d-block d-md-none" />
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-7">
                                            <div className='text-start px-3 px-md-0 pt-3 pt-md-0 textm4'>
                                                <h1 className='text-start text-md-start weight-700 mb-0'>FREIGHT FORWARDER</h1>
                                                <h1 className='text-start text-md-start weight-400 mb-0 mt-1'>LIABILITY</h1>
                                                <hr className='hr-green' style={{ width: "15%" }} />
                                                <div className='weight-400 mt-3'>
                                                    บริษัท ไทยสตาร์ชัวร์ตี้ จำกัด เป็นบริษัทแรกที่นำกรมธรรม์นี้<br />
                                                    มาให้บริการกับบริษัท Freight ที่เป็นสมาชิกของสมาคม TAFA
                                                </div>
                                                <div className='mt-3'>
                                                    <img src={img5} alt="" className="" style={{height:"30px"}}/>
                                                </div>
                                                <div className='mt-5'>
                                                    <div className='row'>
                                                        <div className="col-12 col-md-4 col-lg-4 text-center pb-5 pb-md-0">
                                                            <div className='font-18 weight-700 color-green' style={{ lineHeight: "1.2" }}>
                                                                CARGO <br className='d-none d-md-block' />LIABILITY
                                                            </div>
                                                            <div><img src={icon1} alt="" className="" style={{width:"100px"}}/></div>
                                                            {/* <div><h1 className='weight-700 mb-0'>20</h1></div>
                                                            <div>ล้านบาท</div> */}
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-4 text-center">
                                                            <div className='font-18 weight-700 color-green' style={{ lineHeight: "1.2" }}>
                                                                ERRORS & <br className='d-none d-md-block' />OMISSIONS
                                                            </div>
                                                            <div><img src={icon2} alt="" className="" style={{width:"100px"}}/></div>
                                                            {/* <div><h1 className='weight-700 mb-0'>4</h1></div>
                                                            <div>ล้านบาท</div> */}
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-4 text-center">
                                                            <div className='font-18 weight-700 color-green' style={{ lineHeight: "1.2" }}>
                                                                CUSTOMS<br className='d-none d-md-block' />LIABILITY
                                                            </div>
                                                            <div><img src={icon3} alt="" className=""  style={{width:"100px"}}/></div>
                                                            {/* <div className=''><h1 className='weight-700 mb-0'>2</h1></div>
                                                            <div>ล้านบาท</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='hr-green' />
                                                <div className='font-18 weight-400 color-green text-center' style={{ lineHeight: "1.2" }}>
                                                    <span style={{ textDecoration: "underline" }}>OUR MEMBERS</span>
                                                    <div className='mt-2'><img src={icon4} alt="" className="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
            <Footer />

        </>
    );
};

export default Freightforwarder;