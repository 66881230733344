import React from 'react';
// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import '../assets/css/layout/header.css';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

// import logo from '../assets/img/logosarapadsud.svg';
// import search from '../assets/img/search.svg';
// import notification from '../assets/img/notification.svg';

// import { slide as Menu } from 'react-burger-menu';
import logo from '../assets/img/logotssc2.svg';

import { Link } from "react-tiger-transition";

import Menu from '../components/menu';

const Header = () => {

    // const { t } = useTranslation();

    return (
        <>
            <div className="d-block d-md-none menu-res">
                <Menu />
            </div>
            <header className='position-sticky top-0 d-none d-md-block' style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
                <div className='text-start position-absolute' style={{ top: "12px",zIndex:"999999" }}>
                    <Link to="/home/myRef" transition='default-fade' className='btn-link-default border-0 pe-0'>
                        <img src={logo} alt="" className="" style={{ width: "100px" }} />
                    </Link>
                </div>
                <ul className='menu-top2 mb-0 ms-auto'>

                    <li>
                        <Link to="/home/myRef" transition='default-fade' className='btn-link-default'>
                            HOME
                        </Link>
                    </li>
                    <li>
                        <Link to="/home/aBout" transition='default-fade' className='btn-link-default'>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/home/ourProduct" transition='default-fade' className='btn-link-default'>
                            Our Product
                        </Link>
                    </li>
                    <li>
                        <Link to="/home/exPerience" transition='default-fade' className='btn-link-default'>
                            Experience
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/home/serVices" transition='default-fade' className='btn-link-default'>
                            Services
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/home/conTact" transition='default-fade' className='btn-link-default border-0 pe-0'>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </header>

        </>
    );
};

export default Header;