import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/header';
import Footer from '../layout/footer';


import bg from '../assets/img/bg-con.png';
import bg2 from '../assets/img/bg-freight-2.png';
import bgm from '../assets/img/bg-con-m.png';
import logo from '../assets/img/logotssc2.svg';
import logo1 from '../assets/img/logo-freight-3.svg';

import icon1 from '../assets/img/icon-con-1.png';
import icon2 from '../assets/img/icon-con-2.png';
import icon3 from '../assets/img/icon-con-3.png';
import icon4 from '../assets/img/icon-con-4.png';

import img4 from '../assets/img/img-7.png';
import imgm4 from '../assets/img/img-m-7.png';
// import img5 from '../assets/img/img-5.png';

const Containerdeposit = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* box header  */}
            <Header />

            <div className="wrapper bg-white">
                <section className="box-index-res" >
                    <div className='logo-b-res  d-block d-md-none position-absolute' style={{ left: "20px", top: "20px",zIndex:"999999" }}>
                    <Link to="/home/myRef" transition='default-fade' className='btn-link-default border-0 pe-0'>
                        <img src={logo} alt="" className="" style={{ width: "100px" }} />
                    </Link>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 position-relative">
                                    <img src={bg} alt="" className="w-100 d-none d-md-block" />
                                    <img src={bgm} alt="" className="w-100 d-block d-md-none pt-5" />
                                    <div className='box-text-freight'>
                                        <h1 className='text-center text-md-start weight-700 mb-0'>OUR PRODUCTS</h1>
                                        <div className='color-green color-res-back text-center text-md-start'>ผลิตภัณฑ์</div>
                                    </div>
                                    <div className='box-text-freight-2'>
                                        <img src={logo1} alt="" className="w-75 px-4" />
                                        <div className='d-inline-block'>
                                            <h1 className='text-center text-md-center weight-700 mb-0'>CONTAINER DEPOSIT</h1>
                                            <h1 className='text-center text-md-center weight-400 mb-0 mt-1'>INSURANCE (CDI)</h1>
                                            <hr className='hr-green' />
                                            <div className='color-res-back mt-0 text-center'>กรมธรรม์ค้ำประกันตู้คอนเทนเนอร์</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='box-content box-bg-img' style={{ backgroundImage: `url(${bg2})` }}>
                                    <div className='row py-0 py-md-5 align-items-center'>
                                        <div className="col-12 col-md-5 col-lg-5 bgm4">
                                            <img src={img4} alt="" className="w-100 d-none d-md-block" />
                                            <img src={imgm4} alt="" className="w-100 d-block d-md-none" />
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-7">
                                            <div className='text-start px-3 px-md-0 pt-3 pt-md-0 textm4'>
                                                <h1 className='text-start text-md-start weight-700 mb-0'>CONTAINER DEPOSIT</h1>
                                                <h1 className='text-start text-md-start weight-400 mb-0 mt-1'>INSURANCE (CDI)</h1>
                                                <hr className='hr-green' style={{ width: "15%" }} />
                                                <div className='weight-400 mt-3'>
                                                    กรมธรรม์ฉบับที่ 3 ที่ไทยสตาร์ ชัวร์ตี้ ได้เป็นผู้ค้นคิดขึ้นมาให้บริการกับสมาชิก 4 สมาคม
                                                </div>
                                                <div className='mt-4'>
                                                    <div className='font-18 weight-400 color-green text-center' style={{ lineHeight: "1.2" }}>
                                                        <span style={{ textDecoration: "underline" }}>MEMBERS</span>
                                                    </div>
                                                    <div className='row mt-5 justify-content-center'>
                                                        <div className="col-6 col-md-3 col-lg-2 text-center p-4 p-md-0 px-md-3">
                                                            <div><img src={icon1} alt="" className="w-100" /></div>
                                                        </div>
                                                        <div className="col-6 col-md-3 col-lg-2 text-center p-4 p-md-0 px-md-3">
                                                            <div><img src={icon2} alt="" className="w-100" /></div>
                                                        </div>
                                                        <div className="col-6 col-md-3 col-lg-2 text-center p-4 p-md-0 px-md-3">
                                                            <div><img src={icon3} alt="" className="w-100" /></div>
                                                        </div>
                                                        <div className="col-6 col-md-3 col-lg-2 text-center p-4 p-md-0 px-md-3">
                                                            <div><img src={icon4} alt="" className="w-100" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='weight-400 mt-5 mb-4'>
                                                    มีสมาชิกพันกว่าบริษัทใน 4 สมาคมได้เข้าร่วมโครงการ CDI นี้ พร้อมกับสายเรือที่อยู่
                                                    ในโครงการอีก 10 สายเรือ ให้บริการกรมธรรม์แทนเงินสด หรือหลักทรัพย์ค้ำประกัน
                                                    ตู้คอนเทนเนอร์ ถ้าเป็นจำนวนตู้ในระบบ CDI ปีหนึ่งใช้กรมธรรม์แทนเงินสดเท่ากับ 1 แสนตู้ต่อปี คิดเป็นกรมธรรม์แทนเงินค้ำประกัน 42,000 ล้านบาทต่อปี <br /><br />

                                                    เป็นตัวเลขที่ทำให้สมาชิกของ 4 สมาคมทำงานได้คล่องตัว สามารถนำเงินไปขยายธุรกิจ
                                                    ของตัวเอง

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ background: "#000" }}>
                                    <div className="container p-0 p-md-5">
                                        <div className='d-none d-md-block'>
                                            <iframe width="100%" height="600" src="https://www.youtube.com/embed/NBCh2zZeRxQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                        <div className='d-md-none d-block'>
                                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/NBCh2zZeRxQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
            <Footer />

        </>
    );
};

export default Containerdeposit