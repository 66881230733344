import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// import Header from '../layout/header';
// import Footer from '../layout/footer';

import logo from '../assets/img/logotssc.svg';
import bghome from '../assets/img/bg-home.png';
import bghome2 from '../assets/img/bg-home-2.png';
import bghome3 from '../assets/img/bg-home-3.png';
import bghomem3 from '../assets/img/bg-home-m-3.png';
import bgm4 from '../assets/img/bg-m-4.png';

import cat1 from '../assets/img/cat-1.png';
import cat2 from '../assets/img/cat-2.png';
import cat3 from '../assets/img/cat-3.png';
import catm1 from '../assets/img/cat-m-1.png';
import catm2 from '../assets/img/cat-m-2.png';
import catm3 from '../assets/img/cat-m-3.png';
import icondown from '../assets/img/icon-down.png';

import icon1 from '../assets/img/icon-1.svg';
import icon2 from '../assets/img/icon-2-2.svg';
import icon3 from '../assets/img/icon-3.svg';
import icon4 from '../assets/img/icon-4.svg';
import icon5 from '../assets/img/icon-5.svg';
import icon6 from '../assets/img/icon-6.svg';
import icon7 from '../assets/img/icon-7.svg';
import icon8 from '../assets/img/icon-8.svg';
import icon9 from '../assets/img/icon-9.svg';

import iconm1 from '../assets/img/icon-m-1.svg';
import iconm2 from '../assets/img/icon-m-2-2.svg';
import iconm3 from '../assets/img/icon-m-3.svg';
import iconm4 from '../assets/img/icon-m-4.svg';
import iconm5 from '../assets/img/icon-m-5.svg';
import iconm6 from '../assets/img/icon-m-6.svg';
import iconm7 from '../assets/img/icon-m-7.svg';
import iconm8 from '../assets/img/icon-m-8.svg';
import iconm9 from '../assets/img/icon-m-9.svg';

import icon10 from '../assets/img/icon-10.svg';
import icon11 from '../assets/img/icon-11.svg';
import icon12 from '../assets/img/icon-12.svg';

import img1 from '../assets/img/img-1.png';
import img2 from '../assets/img/img-2.png';
import img3 from '../assets/img/img-3.png';
import imgm3 from '../assets/img/img-m-3.png';

import iemail from '../assets/img/icon-email.png';
import itel from '../assets/img/icon-tel.png';
import copylink from '../assets/img/copylink.png';
import bgfooter from '../assets/img/bg-footer.png';

// import Menu from '../components/menu';
import { slide as Menu } from 'react-burger-menu';
// import scrollToComponent from 'react-scroll-to-component';

import { Link } from "react-tiger-transition";

const Home = () => {

    const { name } = useParams();

    const myRef = useRef(null)
    const aBout = useRef(null)
    const ourProduct = useRef(null)
    const exPerience = useRef(null)
    const serVices = useRef(null)
    const serVicesm = useRef(null)
    const conTact = useRef(null)


    // const myName = useRef(name)
    // const [myname, setMyname] = useState(name);


    // var element = document.getElementById(name);

    // console.log(element)

    useEffect(() => {
        // window.scrollTo(0, 0)
        // scrollToComponent(serVices.current, { offset: 0, align: 'top', duration: 500, ease:'inCirc'});
        if (name === "aBout") {
            aBout.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (name === "ourProduct") {
            ourProduct.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (name === "exPerience") {
            exPerience.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (name === "serVices") {
            serVices.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (name === "serVicesm") {
            serVicesm.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (name === "conTact") {
            conTact.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

    }, [])

    const [menuOpen, setMenuOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen)
    }

    const toggleMenu = () => {
        // setMenuOpen(true); 
        setMenuOpen(false)
    }

    const clicktodiv = (data) => {

        setMenuOpen(false)
        // scrollToComponent(data.current, { offset: -80, align: 'top', duration: 1000, ease: 'inCirc' });
        data.current.scrollIntoView({ offset: { top: -0, left: 0 } })
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <div className="d-block d-md-none menu-res">
                    <div className='right' >

                        <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
                            <div onClick={() => toggleMenu()} className='my-3 text-end px-3 font-20 color-white'>
                                <i className="fa fa-times"></i>
                            </div>
                            <ul className='menu-m'>
                                <li>
                                    <Link to="#" onClick={() => clicktodiv(myRef)}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={() => clicktodiv(aBout)}>
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={() => clicktodiv(ourProduct)}>
                                        Our Product
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={() => clicktodiv(exPerience)}>
                                        Experience
                                    </Link>
                                </li>
                                {/* <li>
                                <Link to="#" onClick={() => clicktodiv(serVicesm)}>
                                    Services
                                </Link>
                            </li> */}
                                <li>
                                    <Link to="#" onClick={() => clicktodiv(conTact)}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>

                        </Menu>

                    </div>
                </div>

                <header className='position-sticky top-0 d-none d-md-block' style={{ height: "51px", backgroundColor: "rgba(0, 0, 0, 0.3)" }}>

                    <ul className='menu-top mb-0 ms-auto'>
                        <li>
                            <Link to="#" transition='default-fade' className='btn-link-default' onClick={() => clicktodiv(myRef)}>
                                HOME
                            </Link>
                        </li>
                        <li>
                            <Link to="#" transition='default-fade' className='btn-link-default' onClick={() => clicktodiv(aBout)}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to="#" transition='default-fade' className='btn-link-default' onClick={() => clicktodiv(ourProduct)}>
                                Our Product
                            </Link>
                        </li>
                        <li>
                            <Link to="#" transition='default-fade' className='btn-link-default' onClick={() => clicktodiv(exPerience)}>
                                Experience
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="#" transition='default-fade' className='btn-link-default' onClick={() => clicktodiv(serVices)}>
                                Services
                            </Link>
                        </li> */}
                        <li>
                            <Link to="#" transition='default-fade' className='btn-link-default border-0 pe-0' onClick={() => clicktodiv(conTact)}>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </header>
                <div className="wrapper bg-white box-index" id='myRef' ref={myRef} >

                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 position-relative">
                                        <img src={bghome} alt="" className="w-100" />
                                        <div className='logo-home'>
                                            <Link to="/home/myRef" transition='default-fade' className='btn-link-default border-0 pe-0'>
                                                <img src={logo} alt="" className="" />
                                            </Link>
                                        </div>
                                        <div className='box-text-home' id='aBout' ref={aBout}>
                                            <h3 className='color-white color-res-green weight-400 text-center text-md-start'>บริษัท ไทยสตาร์ ชัวร์ตี้ จำกัด</h3>
                                            <div className='color-white color-res-back weight-300 mt-3 text-start'>
                                                ได้ประกอบธุรกิจมาเป็นเวลามากกว่า 30 ปี
                                                มีความชำนาญด้านประกันภัยสินค้า (Marine Insurance + Freight Forwarder
                                                Liability Insurance) เป็นผู้ค้นหาและออกแบบกรมธรรม์ให้เหมาะกับความต้องการ
                                                ขององค์กรต่าง ๆ ดังที่บริษัท ไทยสตาร์ชัวร์ตี้ จำกัด ได้คิดค้นผลิตภัณฑ์
                                                มาให้บริการกับองค์กรหลายองค์กรในปัจจุบันนี้ ทำให้สมาชิกในองค์กรนั้น ๆ
                                                ประหยัดค่าใช้จ่าย ลดขั้นตอนการทำงาน มีเงินมาหมุนเวียนในกิจการได้มากขี้น <br /><br />

                                                บริษัท ไทยสตาร์ชัวร์ตี้ จำกัด มีความภูมิใจที่ได้แก้ไขปัญหาขององค์กร
                                                ทำให้สมาชิกขององค์กรสามารถแข่งขันกับธุรกิจต่างชาติ และจะคิดหา
                                                ความคุ้มครองที่เหมาะสมกับองค์กรอื่นๆ ที่ต้องการต่อไป

                                            </div>
                                        </div>

                                    </div>
                                    <div className="w-100 position-relative" >
                                        <img src={bghome2} alt="" className="w-100 d-none d-md-block" />
                                        <div className='box-cat' ref={ourProduct}>
                                            <div className="container p-0" >
                                                <div className='mb-4'>
                                                    <div className='mb-5 mb-md-3 mb-lg-5'>
                                                        <img src={icondown} alt="" className="img-down" />
                                                    </div>
                                                    <div className='name-big'>
                                                        OUR <br />PRODUCTS
                                                    </div>
                                                    <div><h5>ผลิตภัณฑ์</h5></div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-lg-4 pb-3 pb-md-0">
                                                        <Link to="/freightforwarder" transition='default-fade' className='btn-link-default'>
                                                            <div className='position-relative mx-md-3 box-sub'>
                                                                <img src={cat1} alt="" className="w-100 d-none d-md-block" />
                                                                <img src={catm1} alt="" className="w-100 d-block d-md-none" />
                                                                <div className='box-sub-cat pb-3'>
                                                                    <img src={icon10} alt="" className="w-50 w-md-100" />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 pb-3 pb-md-0">
                                                        <Link to="/default" transition='default-fade' className='btn-link-default'>
                                                            <div className='position-relative mx-md-3 box-sub'>
                                                                <img src={cat2} alt="" className="w-100 d-none d-md-block" />
                                                                <img src={catm2} alt="" className="w-100 d-block d-md-none" />
                                                                <div className='box-sub-cat pb-3'>
                                                                    <img src={icon11} alt="" className="w-50 w-md-100" />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 pb-3 pb-md-0">
                                                        <Link to="/containerdeposit" transition='default-fade' className='btn-link-default'>
                                                            <div className='position-relative mx-md-3 box-sub'>
                                                                <img src={cat3} alt="" className="w-100 d-none d-md-block" />
                                                                <img src={catm3} alt="" className="w-100 d-block d-md-none" />
                                                                <div className='box-sub-cat pb-3'>
                                                                    <img src={icon12} alt="" className="w-50 w-md-100" />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='d-none d-md-block mt-1 mt-md-5' ref={serVices}>
                                                    <ul className='cat-icon'>
                                                        <li>
                                                            <Link to="/marine" transition='default-fade' className='btn-link-default'>
                                                                <img src={icon1} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/carrireliability" transition='default-fade' className='btn-link-default'>
                                                                <img src={icon2} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/publicliability" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={icon3} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/allrisksinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={icon4} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/fireinsurance" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={icon5} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='d-none d-md-block'>
                                                    <ul className='cat-icon'>
                                                        <li>
                                                            <Link to="/motorinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={icon6} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/politycalinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={icon7} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/erectioninsurance" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={icon8} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/professionalindemity" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={icon9} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li style={{ opacity: 0 }}><img src={icon5} alt="" className="" /></li>
                                                    </ul>
                                                </div>

                                                <div className='d-block d-md-none' ref={serVicesm}>
                                                    <ul className='cat-icon'>
                                                        <li>
                                                            <Link to="/marine" transition='default-fade' className='btn-link-default'>
                                                                <img src={iconm1} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/carrireliability" transition='default-fade' className='btn-link-default'>
                                                                <img src={iconm2} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/publicliability" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={iconm3} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/allrisksinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={iconm4} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/fireinsurance" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={iconm5} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/motorinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={iconm6} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/politycalinsurance" transition='default-fade' className='btn-link-default'>
                                                                <img src={iconm7} alt="" className="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/erectioninsurance" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={iconm8} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li>
                                                            {/* <Link to="/professionalindemity" transition='default-fade' className='btn-link-default'> */}
                                                            <img src={iconm9} alt="" className="" />
                                                            {/* </Link> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 position-relative box-bg-3">
                                        <img src={bghome3} alt="" className="w-100 d-none d-md-block" />
                                        <img src={bghomem3} alt="" className="w-100 d-block d-md-none" />
                                        <div className='box-bg3' ref={exPerience} >
                                            <div className='name-big color-white text-center text-md-start' >EXPERIENCE</div>
                                            <div className='color-white text-center text-md-start'><h5 className=' weight-400'>ผลงาน และความภาคภูมิใจ</h5></div>
                                            <div className='row mt-5 align-items-center'>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <img src={img1} alt="" className="w-100 px-3 px-md-0" />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className='text-start color-white px-3 px-md-0 pt-3 pt-md-0'>
                                                        <h3>เข้าแสดงความยินดี</h3>
                                                        <div className='weight-400'>
                                                            กับคุณจุฬา สุขมานพ<br />
                                                            ผู้อำนวยการสำนักงานการบินพลเรือนแห่งประเทศไทย
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-5 align-items-center'>
                                                <div className="col-12 col-md-7 col-lg-8 col-xl-8 d-flex d-md-none">
                                                    <img src={img2} alt="" className="w-100 px-3 px-md-0" />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-4 col-xl-4">
                                                    <div className='text-start color-white px-3 px-md-0 pt-3 pt-md-0'>
                                                        <h3>กิจกรรม TSSC</h3>
                                                        <div className='weight-400 pe-md-3 pe-lg-3 pe-xl-5'>
                                                            พร้อมด้วยคณะกรรมการสมาคมตัวแทนออกของรับ
                                                            อนุญาตไทย และสมาคมชิปปิ้งแห่งประเทศไทย เข้าชี้แจง
                                                            โครงการ CDI กับสายเรือต่างๆ เพื่อนำเสนอประโยชน์ในการเข้าร่วมโครงการ CDI
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-8 col-xl-8 d-none d-md-flex">
                                                    <img src={img2} alt="" className="w-100" />
                                                </div>
                                            </div>
                                            <div className='d-block d-md-none'>
                                                <img src={bgm4} alt="" className="w-100" />
                                            </div>
                                            <div className='row mt-5 pt-3 align-items-center box-rang-res d-flex d-md-none'>
                                                <div className="col-6 col-md-5 col-lg-6">
                                                    <img src={imgm3} alt="" className="w-100 px-3 px-md-0 d-block d-md-none" />
                                                </div>
                                                <div className="col-6 col-md-7 col-lg-6">
                                                    <div className='text-start color-white px-3 px-md-0 mb-5 mb-md-0 mb-0 pt-0 mb-lg-5 pb-lg-5'>
                                                        <h3>รางวัลดีเด่นประจำปี 2549</h3>
                                                        <div className='weight-400 mb-lg-4 pb-lg-4'>
                                                            คัดเลือกให้เป็นนายหน้าประกันวินาศภัยประเภทนิติบุคคลคุณภาพดีเด่น
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-start color-white pe-md-5 position-absolute d-none d-md-block' style={{ bottom: "10%", left: "50%" }}>
                                            <h3>รางวัลดีเด่นประจำปี 2549</h3>
                                            <div className='weight-400 mb-lg-4 pb-lg-4'>
                                                คัดเลือกให้เป็นนายหน้าประกันวินาศภัยประเภทนิติบุคคลคุณภาพดีเด่น
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                </div>

                <div className='box-footer pb-0 pb-md-5' style={{ backgroundImage: `url(${bgfooter})`, overflow: "hidden" }}>
                    <div className='row align-items-center'>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className='name-big color-white text-start text-md-start px-0 px-md-0'>CONTACT US</div>
                            <div className='color-white text-start text-md-start px-0 px-md-0'><h5 className=' weight-400'>ติดต่อเรา</h5></div>
                            <div className='text-start text-md-start color-white mt-1 mt-md-5 px-0 px-md-0'>
                                บริษัท ไทยสตาร์ ชัวร์ตี้ จำกัด <br />
                                89/12 อาคารอมรพันธุ์ 205 ทาวเวอร์ 1 ชั้น 11 <br />
                                ซอยนาทอง ถนนรัชดาภิเษก แขวงดินแดง <br />
                                เขตดินแดง กรุงเทพมหานคร 10400

                            </div>
                            <div className='textcenter text-md-start mt-3 mt-md-5 mb-5'>
                                <div className='d-inline-block text-center'>
                                    <div><img src={iemail} alt="" className="icon-email" /></div>
                                    <div className='color-white'>thaistar@tssc.co.th</div>
                                </div>
                                <div className='d-inline-block text-center ms-5'>
                                    <div><img src={itel} alt="" className="icon-tel" /></div>
                                    <div className='color-white'>0-2245-4082 ถึง 3</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 px-0 px-md-3">
                            <div className='d-none d-md-block'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.095186794498!2d100.57037071488199!3d13.773133000509459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29eca0a4dc2e7%3A0xabfa2e62099e3c63!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5hOC4l-C4ouC4quC4leC4suC4o-C5jOC4iuC4seC4p-C4o-C5jOC4leC4teC5iSDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1642592661102!5m2!1sth!2sth" width="100%" height="400" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
                            </div>
                            <div className='d-block d-md-none' style={{ marginBottom: "-5px" }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.095186794498!2d100.57037071488199!3d13.773133000509459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29eca0a4dc2e7%3A0xabfa2e62099e3c63!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5hOC4l-C4ouC4quC4leC4suC4o-C5jOC4iuC4seC4p-C4o-C5jOC4leC4teC5iSDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1642592661102!5m2!1sth!2sth" width="100%" height="250" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={conTact} className='text-end bg-white box-copylink blue' >
                    <span className='float-start text-start'>
                        <Link to="/policy" transition='default-fade' className='btn-link-default'>
                            <div className='policy-footer' style={{ lineHeight: "1" }}><b>PERSONAL DATA <br className='d-inblock d-md-none' />PROTEXTION POLICY</b></div>
                            <div className='policy-footer-thai'>
                                นโยบายคุ้มครองข้อมูลส่วนบุคคล
                            </div>
                        </Link>
                    </span>
                    <img src={copylink} alt="" className="policy-footer-img" />
                </div>
            </div>
            {/* <Footer /> */}

        </>
    );
};

export default Home;