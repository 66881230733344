import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/header';
import Footer from '../layout/footer';


import bg from '../assets/img/bg-marine-1-1.jpg';
import bg2 from '../assets/img/bg-marine-1-2.jpg';
import bgm from '../assets/img/bgm-marine-1-1.jpg';
// import bgm3 from '../assets/img/bg-freight-m-3.png';
import logo from '../assets/img/logotssc2.svg';
import logo1 from '../assets/img/logo-marine-1.svg';

import img4 from '../assets/img/img-marine-1.jpg';
import imgm4 from '../assets/img/imgm-marine-1.jpg';

const Marine = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <div className="wrapper bg-white ">
                <section className="box-index-res" >
                    <div className='logo-b-res  d-block d-md-none position-absolute' style={{ left: "20px", top: "20px",zIndex:"999999" }}>
                    <Link to="/home/myRef" transition='default-fade' className='btn-link-default border-0 pe-0'>
                        <img src={logo} alt="" className="" style={{ width: "100px" }} />
                    </Link>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 position-relative">
                                    <img src={bg} alt="" className="w-100 d-none d-md-block" />
                                    <img src={bgm} alt="" className="w-100 d-block d-md-none pt-5" />
                                    <div className='box-text-freight'>
                                        <h1 className='text-center text-md-start weight-700 mb-0'>OUR PRODUCTS</h1>
                                        <div className='color-green color-res-back text-center text-md-start'>ผลิตภัณฑ์</div>
                                    </div>
                                    <div className='box-text-freight-2'>
                                        <img src={logo1} alt="" className="w-75 px-4" />
                                        <div className='d-inline-block'>
                                            <h1 className='text-center text-md-center weight-700 mb-0'>MARINE / CARGO</h1>
                                            <h1 className='text-center text-md-center weight-400 mb-0 mt-1'>INSURANCE</h1>
                                            <hr className='hr-green' />
                                            <div className='mt-0 text-center'>ประกันขนส่งทางเรือ</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='box-bg-img-marine' style={{ backgroundImage: `url(${bg2})` }}>
                                    <div className='row align-items-center'>
                                        <div className="col-12 col-md-7 col-lg-7 bgm4marine">
                                            <img src={img4} alt="" className="w-100 d-none d-md-block" />
                                            <img src={imgm4} alt="" className="w-100 d-block d-md-none" />
                                        </div>
                                        <div className="col-12 col-md-5 col-lg-5">
                                            <div className='text-start p-5 p-md-5 textm4'>
                                                <h1 className='text-start text-md-start weight-700 mb-0'>MARINE / CARGO</h1>
                                                <h1 className='text-start text-md-start weight-400 mb-0 mt-1'>INSURANCE</h1>
                                                <hr className='hr-green' style={{ width: "15%" }} />
                                                <div className='weight-400 mt-3'>
                                                ผลงานการจัดทำประกันขนส่งโรงงานที่ผลิตเสร็จแล้ว<br />ไปยังประเทศญี่ปุ่น                                                   
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
            <Footer />

        </>
    );
};

export default Marine;